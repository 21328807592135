import React from 'react';
import './ContactInfo.css';

const ContactInfo = () => {
  return (
    <div className="contact-container">
      <h2>Get in Touch</h2>
      <p>
        Ready to get started on a project? Want to schedule a stroll through our slab warehouse or tile showroom for inspiration? Interested in speaking with us to learn what material best fits your needs?
      </p>
      <p>
        We’d be honored to partner with you on your project. Let’s talk shop.
      </p>
      <p>
        Contact us to make an appointment today.
      </p>
      <div className="contact-details">
        <div className="contact-item">
          <i className="fas fa-map-marker-alt"></i>
          <p>165 Broadway 23 floor, Manhattan, NY 10006</p>
        </div>
        <div className="contact-item">
          <i className="fas fa-clock"></i>
          <p>Showroom Appointments Available: Monday – Friday 9am to 5pm & Saturdays 9am - 2pm</p>
        </div>
        <div className="contact-item">
          <i className="fas fa-phone"></i>
          <p>+1 (917) 640-7693</p>
        </div>
        <div className="contact-item">
          <i className="fas fa-envelope"></i>
          <p>info@ncstoneandtiles.com</p>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
