import React from 'react';
import './ServicesDescription.css';

const ServicesDescription = () => {
  return (
    <div className="services-container">
      <h2>Our Services Include:</h2>
      <ul className="services-list">
        <li>
          <h3>Custom Countertop Design and Installation</h3>
          <p>
            Our expert designers work with you to create custom countertops that
            perfectly match your style and functional needs. From initial consultation
            to final installation, we ensure a seamless process and stunning results.
          </p>
        </li>
        <li>
          <h3>Restoration</h3>
          <p>
            We offer comprehensive restoration services to bring your existing stone surfaces
            back to life. Whether it's repairing cracks, removing stains, or polishing surfaces,
            our restoration services will rejuvenate your countertops.
          </p>
        </li>
        <li>
          <h3>Stone Wrapping</h3>
          <p>
            Enhance the aesthetic appeal of your property with our professional stone wrapping
            services. Ideal for both interior and exterior applications, stone wrapping provides
            a sophisticated finish.
          </p>
        </li>
        <li>
          <h3>Taff Siller</h3>
          <p>
            Our Taff Siller services are designed to protect and preserve your stone surfaces.
            We apply high-quality sealants to enhance the durability and longevity of your countertops.
          </p>
        </li>
        <li>
          <h3>Fabrication and Installation</h3>
          <p>
            From cutting and shaping the stone to the precise installation in your space, our
            fabrication and installation services ensure a perfect fit and flawless finish for your
            countertops.
          </p>
        </li>
        <li>
          <h3>Stone Selection Assistance</h3>
          <p>
            We assist you in selecting the perfect stone for your project from our wide range of natural
            and engineered stones. Our experts provide guidance based on your aesthetic and functional
            requirements.
          </p>
        </li>
        <li>
          <h3>Edge Profiling</h3>
          <p>
            We offer a variety of edge profiles to enhance the look of your countertops. Whether you prefer
            a classic or modern style, our edge profiling options will add a unique touch to your surfaces.
          </p>
        </li>
        <li>
          <h3>Surface Finishing</h3>
          <p>
            Our surface finishing services include polishing, honing, and brushing to achieve the desired
            texture and shine for your stone surfaces. We ensure that your countertops have a flawless finish.
          </p>
        </li>
        <li>
          <h3>Maintenance and Care</h3>
          <p>
            We provide maintenance and care instructions to keep your stone surfaces looking their best. Our
            team offers periodic maintenance services to ensure the longevity and beauty of your countertops.
          </p>
        </li>
        <li>
          <h3>Custom Projects</h3>
          <p>
            In addition to countertops, we undertake custom stone projects such as fireplace surrounds, shower
            enclosures, and outdoor kitchens. Our team is equipped to handle unique and complex projects with
            precision and expertise.
          </p>
        </li>
      </ul>
    </div>
  );
};

export default ServicesDescription;
