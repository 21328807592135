import React from 'react';
import './AboutValues.css';
import Team from '../assets/team.jpg'

const AboutValues = () => {
  return (
    <div className="about-values-container">
      <div className="about-values-content">
        <h2>Well-earned Reputation. Even Higher Standards.</h2>
        <p>
          Established in 2018, we take pride in having earned a
          reputation for quality craftsmanship, expert precision, and meaningful customer experiences.
          When it comes to the fabrication and installation of natural stone and tile for homes and custom
          projects in southern and coastal Maine, we strive to impress and inspire.
        </p>
        <p>
          Consistent, attentive, and precise, we are fully committed to quality and uphold ourselves to the
          highest standards of workmanship in every project we do. From guiding customers to the perfect
          slab of stone for their kitchen countertops to final installation of tile flooring, we deliver a seamless
          experience with impressive results.
        </p>
        <h3>Our Values</h3>
        <ul>
          <li>Take Ownership</li>
          <li>Honor the Craft</li>
          <li>Culture is Collective</li>
          <li>Stay Humble and Helpful</li>
        </ul>
      </div>
      <div className="about-values-image">
        <img src={Team} alt="Team" />
      </div>
    </div>
  );
};

export default AboutValues;
