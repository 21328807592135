import React from 'react';
import './AboutSubNav.css';

const SubNavbar = () => {
  return (
    <div className="sub-navbar">
      <ul className="sub-navbar-menu">
        <li><a href="/about">About</a></li>
        <li><a href="/careers">Careers</a></li>
        <li><a href="/installation">Installation and Process</a></li>
      </ul>
    </div>
  );
};

export default SubNavbar;
