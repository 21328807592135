import React from 'react';
import './Career.css';

const Career = () => {
  return (
    <div className="career-container">
      <div className="career-content">
        <h2>Careers</h2>
        <p>
        At New Century Stone and Tiles, we are proudly employee-owned, deeply committed to our craft, and dedicated to fostering a work-life balance that sets us apart. A career with New Century Stone and Tiles is more than just a job; it is a way of life.
        </p>
        <a href='/careers'><button>LEARN MORE</button></a>
      </div>
    </div>
  );
};

export default Career;
