import React from 'react';
import ContactInfo from '../components/ContactInfo';
import ContactForm from '../components/ContactForm';


const Contacts = () => {
  return (
    <div>
        <ContactInfo />
        <ContactForm />
    </div>
  );
};

export default Contacts;
