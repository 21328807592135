import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import CompanyLogo from '../assets/logo.png';

const NavbarContainer = styled.nav`
  background-color: #333;
  color: white;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  align-items: center;
  
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Logo = styled.div`
  img {
    width: 150px; /* Set the desired width */
    height: auto; /* Maintain aspect ratio */
    cursor: pointer; /* Optional: Change cursor to pointer */
  }
`;

const NavLinks = styled.div`
  display: flex; /* Always display the links on small screens */
  flex-direction: row; /* Display in a row by default */

  a {
    color: white;
    text-decoration: none;
    margin-right: 1rem;

    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    display: flex; /* Make sure links are always displayed on small screens */
  }
`;

const NavLink = styled(Link)`
  color: white;
  text-decoration: none;
  margin-right: 1rem;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    padding: 10px;
    border-bottom: 1px solid white;
  }
`;

// Remove MenuIcon component as it's no longer needed

function Navbar() {
  return (
    <NavbarContainer>
      <Logo><img src={CompanyLogo} alt="Company Logo" /></Logo>
      <NavLinks>
        <NavLink to="/">Home</NavLink>
        <NavLink to="/about">About Us</NavLink>
        <NavLink to="/services">Services</NavLink>
        <NavLink to="/inventory">Inventory</NavLink>
        <NavLink to="/projects">Projects</NavLink>
        <NavLink to="/contact">Contact</NavLink>
      </NavLinks>
    </NavbarContainer>
  );
}

export default Navbar;
