import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './UserInfo.css';

const UserInfo = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { estimates } = location.state || {};

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    const userInfo = {
      name,
      email,
      phone,
      address
    };
    // Navigate to the receipt page with both userInfo and estimates data
    navigate('/calculator/receipt', { state: { estimates, userInfo } });
  };

  if (!estimates || estimates.length === 0) {
    return <p>Error: Missing data. Please go back and complete the form again.</p>;
  }

  return (
    <div className="user-info-container">
      <h1>Enter Your Information</h1>
      <form onSubmit={handleSubmit}>
        <label>
          Name:
          <input type="text" value={name} onChange={(e) => setName(e.target.value)} required />
        </label>
        <label>
          Email:
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
        </label>
        <label>
          Phone:
          <input type="text" value={phone} onChange={(e) => setPhone(e.target.value)} required />
        </label>
        <label>
          Address:
          <input type="text" value={address} onChange={(e) => setAddress(e.target.value)} required />
        </label>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default UserInfo;
