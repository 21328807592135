import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import './RenovationType.css';

const RenovationType = ({ onSelection }) => {
    const location = useLocation();
    const cartItems = location.state?.cartItems || [];

    const [selectedTypes, setSelectedTypes] = useState({});

    const handleSelection = (type, productID) => {
        setSelectedTypes(prevState => ({
            ...prevState,
            [productID]: type
        }));
    };

    const handleContinue = () => {
        if (Object.keys(selectedTypes).length === cartItems.length) {
            // All stones have a selected renovation type, proceed to the calculator
            onSelection(cartItems, selectedTypes); // Pass both cartItems and selectedTypes
        } else {
            alert("Please select a renovation type for each stone.");
        }
    };

    return (
        <div className="renovation-type-container">
            {cartItems.length === 0 ? (
                <div>No stones in your cart. Please add stones before proceeding.</div>
            ) : (
                <div>
                    {cartItems.map(slab => (
                        <div key={slab.productID} className="renovation-item">
                            <h3 className="renovation-title">
                                {slab.name} - Price: {slab.price}
                            </h3>
                            <p className="renovation-instruction">
                                Select the renovation type for Product: {slab.productID}
                            </p>
                            <select
                                className="renovation-dropdown"
                                value={selectedTypes[slab.productID] || ""}
                                onChange={(e) => handleSelection(e.target.value, slab.productID)}
                            >
                                <option value="">Select Renovation Type</option>
                                <option value="Bathroom">Bathroom</option>
                                <option value="Fireplace">Fireplace</option>
                                <option value="Kitchen">Kitchen</option>
                                <option value="Outdoor Kitchen">Outdoor Kitchen</option>
                            </select>
                        </div>
                    ))}
                    <button className="continue-button" onClick={handleContinue}>
                        Continue
                    </button>
                </div>
            )}
        </div>
    );
};

export default RenovationType;
