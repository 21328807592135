import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import slabsData from '../data/slabsData.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import './InventorySlabs.css';

const InventorySlabs = () => {
    const [cartItems, setCartItems] = useState([]);
    const [showCart, setShowCart] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const slabsPerPage = 24;

    const navigate = useNavigate();

    // Function to categorize slabs by price type
    const categorizeSlabsByPriceType = (slabsData) => {
        const slabsByFt2 = [];
        const slabsByTx = [];

        Object.keys(slabsData).forEach((key) => {
            const slab = slabsData[key];
            if (slab.price.includes('ft2')) {
                slabsByFt2.push(slab);
            } else if (slab.price.includes('TX')) {
                slabsByTx.push(slab);
            }
        });

        return {
            ft2: slabsByFt2,
            tx: slabsByTx
        };
    };

    // Function to add $10 to the price
    const addPriceIncrease = (price) => {
        const priceValue = parseFloat(price.match(/[\d.]+/)); // Extract the numeric value
        return (priceValue + 10).toFixed(2); // Add $10 and format to two decimal places
    };

    // Categorize slabs on component mount
    const categorizedSlabs = categorizeSlabsByPriceType(slabsData);

    const indexOfLastSlab = currentPage * slabsPerPage;
    const indexOfFirstSlab = indexOfLastSlab - slabsPerPage;
    const currentSlabs = categorizedSlabs.ft2.slice(indexOfFirstSlab, indexOfLastSlab); // Display only ft2 slabs for now

    const addToCart = (slab) => {
        if (!cartItems.some(item => item.productID === slab.productID)) {
            setCartItems([...cartItems, slab]);
        }
    };

    const removeFromCart = (productID) => {
        setCartItems(cartItems.filter(item => item.productID !== productID));
    };

    const toggleCart = () => {
        setShowCart(!showCart);
    };

    const handleCheckout = () => {
        if (cartItems.length > 0) {
            navigate('/calculator', { state: { cartItems } });
        } else {
            alert('No stones in your cart. Please add stones before proceeding.');
        }
    };

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div className="inventory-container">
            <div className="search-cart-container">
                <input
                    type="text"
                    placeholder="Search slabs..."
                    className="search-box"
                />
                <div className="cart-icon" onClick={toggleCart}>
                    <FontAwesomeIcon icon={faShoppingCart} size="2x" />
                    <span className="cart-count">{cartItems.length}</span>
                </div>
            </div>

            <div className="slabs-grid">
                {currentSlabs.map((slab) => (
                    <div key={slab.productID} className="inventory-card">
                        <img src={slab.image} alt={slab.name} />
                        <div className="inventory-card-content">
                            <h3>{slab.name}</h3>
                            <p>{slab.details}</p>
                            <div className="inventory-card-price">
                                {`Price: ${addPriceIncrease(slab.price)} USD / ft2`}
                            </div>
                            <div className="inventory-card-actions">
                                <button onClick={() => addToCart(slab)}>Add</button>
                                <button onClick={() => removeFromCart(slab.productID)}>Remove</button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <div className="pagination-container">
                {currentPage > 1 && (
                    <button
                        className="pagination-button"
                        onClick={() => paginate(currentPage - 1)}
                    >
                        Prev
                    </button>
                )}

                {currentPage > 3 && (
                    <>
                        <button
                            className={`pagination-button ${currentPage === 1 ? 'active' : ''}`}
                            onClick={() => paginate(1)}
                        >
                            1
                        </button>
                        <button className="pagination-button">...</button>
                    </>
                )}

                {[...Array(Math.ceil(categorizedSlabs.ft2.length / slabsPerPage)).keys()].slice(Math.max(0, currentPage - 2), currentPage + 1).map(number => (
                    <button
                        key={number + 1}
                        className={`pagination-button ${currentPage === number + 1 ? 'active' : ''}`}
                        onClick={() => paginate(number + 1)}
                    >
                        {number + 1}
                    </button>
                ))}

                {currentPage < Math.ceil(categorizedSlabs.ft2.length / slabsPerPage) - 2 && (
                    <>
                        <button className="pagination-button">...</button>
                        <button
                            className={`pagination-button ${currentPage === Math.ceil(categorizedSlabs.ft2.length / slabsPerPage) ? 'active' : ''}`}
                            onClick={() => paginate(Math.ceil(categorizedSlabs.ft2.length / slabsPerPage))}
                        >
                            {Math.ceil(categorizedSlabs.ft2.length / slabsPerPage)}
                        </button>
                    </>
                )}

                {currentPage < Math.ceil(categorizedSlabs.ft2.length / slabsPerPage) && (
                    <button
                        className="pagination-button"
                        onClick={() => paginate(currentPage + 1)}
                    >
                        Next
                    </button>
                )}
            </div>

            {showCart && (
                <div className="cart-popup">
                    <h2>Your Cart</h2>
                    {cartItems.length === 0 ? (
                        <p>Your cart is empty.</p>
                    ) : (
                        <ul>
                            {cartItems.map(item => (
                                <li key={item.productID}>
                                    {item.name} - Price: {addPriceIncrease(item.price)} USD / ft2
                                    <button onClick={() => removeFromCart(item.productID)}>Remove</button>
                                </li>
                            ))}
                        </ul>
                    )}
                    <div className="cart-buttons">
                        <button onClick={toggleCart}>Close Cart</button>
                        <button onClick={handleCheckout}>Checkout</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default InventorySlabs;
