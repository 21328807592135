import React from 'react';
import ServicesAbout from '../components/ServicesAbout';
import ServicesDescription from '../components/ServicesDescription';

const Services = () => {
  return (
    <div>
        <ServicesAbout />
        <ServicesDescription />
    </div>
  );
};

export default Services;
