import one from "../assets/HM Savannah GA/1-Feb 05, 2024 22-59-39-D6WJ.jpg"
import two from "../assets/HM Savannah GA/2-Feb 05, 2024 22-59-46-gavs.jpg"
import three from "../assets/HM Savannah GA/3-Feb 05, 2024 23-01-15-5hsd.jpg"
import four from "../assets/HM Savannah GA/4-Feb 05, 2024 23-01-30-aJWo.jpg"
import five from "../assets/HM Savannah GA/5-Feb 05, 2024 23-01-51-Ha1a.jpg"
import six from "../assets/HM Savannah GA/6-Feb 05, 2024 23-02-13-scKd.jpg"
import seven from "../assets/HM Savannah GA/7-Feb 05, 2024 23-02-49-AGgP.jpg"
import eight from "../assets/HM Savannah GA/8-Feb 05, 2024 23-02-53-aAvL.jpg"
import nine from "../assets/HM Savannah GA/9-Feb 05, 2024 23-02-58-HpTW.jpg"
import ten from '../assets/HM Savannah GA/10-Feb 06, 2024 23-53-38-awxv.jpg'
import eleven from '../assets/HM Savannah GA/11-Feb 17, 2024 22-02-41-6Ypv.jpg'

// src/data/sampleProject.js
const sampleProject = {
    title: "H&M Store Project in Savannah, GA",
    overview: "Complete flooring installation for H&M's retail space in Savannah, GA, focusing on precision and durability to create a polished and cohesive look for a high-traffic commercial environment.",
    video: "https://res.cloudinary.com/dxlbndkts/video/upload/v1726066520/H_M_Store_wlvwfk.mp4", // Project embed link
    images: [
      { src: one, alt: "Result 1" },
      { src: three, alt: "Result 3" },
      { src: four, alt: "Result 4" },
      { src: five, alt: "Result 5" },
      { src: six, alt: "Result 6" },
      { src: seven, alt: "Result 7" },
      { src: eight, alt: "Result 8" },
      { src: nine, alt: "Result 9" },
      { src: ten, alt: "Result 10" },
    ],
    description: "We were entrusted with the task of transforming the flooring for H&M’s retail space in Savannah, Georgia. This project involved the meticulous installation of high-quality tiles across the store's expansive floor area. Our team ensured precision and durability, laying each tile with care to meet the store’s high traffic demands. The result is a seamless, polished finish that not only enhances the store’s aesthetic but also stands up to daily wear and tear. This project showcases our commitment to delivering top-notch craftsmanship for commercial spaces.",
    date: "December 21, 2023 - Feb 22, 2024",
    location: "Savannah, GA"
  };
  
  export default sampleProject;
  