import React from 'react';
import './ServicesAbout.css';

const ServicesAbout = () => {
    return (
        <div className="services-container">
            <h2 className="services-title">Our Services</h2>
            <h3 className="services-subtitle">Stone Countertops by NC Stone and Tile</h3>
            <p className="services-description">
                At NC Stone and Tile, we specialize in the design, fabrication, and installation of custom countertops, offering the widest selection of both natural and engineered stones. Our services cater to a variety of projects, including newly constructed residences, condominium cosmetic rehabs, hotel renovations, and other residential or commercial properties. Our experienced team of professionals is committed to delivering unparalleled customer service, exceptional craftsmanship, and top-quality products at a remarkable value.
            </p>
        </div>
    );
};

export default ServicesAbout;
