import React from 'react';
import ImageSlider from './ImageSlider';
import StoneSurfaces from './StoneSurfaces';
import Tile from './Tile';
import Career from './Career';
import './Home.css'; // Import CSS for home page styles if needed
import CalculatorInfo from './CalculatorInfo';

const Home = () => {
  return (
    <div>
      <ImageSlider />
      <div className="home-section">
        <h2>Empowered by a passion to serve.</h2>
        <p>
          New Century Stone and Tiles is a leading provider of high-quality stone products and services, established in 2018. Specializing in restoration, stone wrapping, taff siller, and both fabrication and installation, we cater to diverse project needs. Our services extend across the US, Europe, and other countries, ensuring that no matter where our clients are, they receive expert craftsmanship and dedicated service.
        </p>
        <a href='/contact'><button className="contact-button">CONTACT US TODAY</button></a>
      </div>
      <CalculatorInfo />
      <StoneSurfaces />
      <Tile />
      <Career />
    </div>
  );
};

export default React.memo(Home);
