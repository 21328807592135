import React from 'react';
import './Contact.css';

const Contact = () => {
  return (
    <div className="contact-section">
      <h2 className="contact-title">New Century Stone & Tiles</h2>
      <p className="contact-description">
        If you are a contractor, interior designer, builder, or dealer looking for impeccable custom fabrication and installation services, please reach out to us! 
      </p>
      <a href="/contact" className="section-button">CONTACT US TODAY</a>
    </div>
  );
};

export default Contact;
