import React from 'react';
import InventorySlabs from '../components/InventorySlabs';

function inventory() {
  return (
    <div>
      <div style={{
      padding: '20px',
      backgroundColor: '#dee3e2',
      borderRadius: '8px',
      marginBottom: '20px',
      textAlign: 'center'
      }}>
        <h1 style={{
          fontFamily: `'Roboto', sans-serif`,
          fontSize: '3rem',
          fontWeight: 700,
          color: '#2C3E50',
          textTransform: 'uppercase',
          letterSpacing: '2px',
          marginBottom: '20px',
          textShadow: '2px 2px 5px rgba(0, 0, 0, 0.1)',
        }}>
          inventory:
        </h1>
        <h2 style={{
          fontFamily: `'Roboto', sans-serif`,
          fontSize: '2rem',
          fontWeight: 500,
          color: '#34495E',
          marginBottom: '15px',
          letterSpacing: '1px',
          textShadow: '1px 1px 3px rgba(0, 0, 0, 0.05)',
        }}>
          Available Slabs
        </h2>
      </div>

      <InventorySlabs />
    </div>
  );
}

export default inventory;
