import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Navbar from './components/Navbar';
import Home from './components/Home';
import About from './pages/About';
import Footer from './components/Footer';
import Contact from './components/Contact';
import Careers from './pages/Careers';
import Installation from './pages/Installation'
import './App.css'; // Import CSS for global styles if needed
import Projects from './pages/Projects';
import Fairfield from './data/Fairfield'
import Services from './pages/Services';
import ProjectDetails from './components/ProjectsDetails';
import HnMProject from './data/HnM'
import Office from './data/Office'
import Hamptons from './data/Hamptons'
import Contacts from './pages/Contact';
import Calculator from './pages/Calculator';
import UserInfo from './components/UserInfo';
import Receipt from './components/Receipt';
import Inventory from './pages/Inventory';

function App() {

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <Router>
      <div>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/installation" element={<Installation />} />
          <Route path='/projects' element={<Projects />} />
          <Route path='/services' element={<Services />} />
          <Route path='/projects/fairfield' element={<ProjectDetails project={Fairfield} />} />
          <Route path='/projects/H&M' element={<ProjectDetails project={HnMProject} />} />
          <Route path='/projects/office' element={<ProjectDetails project={Office} />} />
          <Route path='/projects/hamptons' element={<ProjectDetails project={Hamptons} />} />
          <Route path='/contact' element={<Contacts />} />
          <Route path='/calculator' element={<Calculator />} />
          <Route path="/calculator/user-info" element={<UserInfo />} />
          <Route path='/calculator/receipt' element={<Receipt />} />
          <Route path='/inventory' element={<Inventory />} />
          {/* Add other routes here */}
        </Routes>
        <Contact />
        <Footer />
      </div>
    </Router>
  );
}

export default App;
