import React from 'react';
import SubNavbar from '../components/AboutSubNav';
import WorkWithUs from '../components/WorkWithUs';

const AboutCareers = () => {
  return (
    <div>
      <SubNavbar />
      <WorkWithUs />
    </div>
  );
};

export default AboutCareers;
