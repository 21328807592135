import React from 'react';
import SubNavbar from '../components/AboutSubNav';
import AboutValues from '../components/AboutValues';
import CareersAndInstallation from '../components/CareersAndInstallation';
import PartnersCarousel from '../components/PartnersCarousel';

const About = () => {
  return (
    <div>
      <SubNavbar />
      <AboutValues />
      <CareersAndInstallation />
      <PartnersCarousel />
    </div>
  );
};

export default About;
