import React from "react";
import "./ProjectsCards.css";
import NewFairfield from '../assets/NewFairfield.jpeg'
import HnM from '../assets/H&M.jpg'
import Fireplace from '../assets/Fireplace Hamptons.jpg'
import Office from '../assets/Office Stone Work.jpg'

const categories = [
  {
    to: "/projects/fairfield",
    imgSrc:
      NewFairfield,
    alt: "img1",
    description: "New Fairfield Project",
    className: "grid-one",
  },
  {
    to: "projects/H&M",
    imgSrc:
    HnM,
    alt: "img2",
    description: "H&M shop",
    className: "grid-two",
  },
  {
    to: "projects/office",
    imgSrc:
    Office,
    alt: "img3",
    description: "Stone Work Office",
    className: "grid-four",
  },
  {
    to: "projects/hamptons",
    imgSrc:
    Fireplace,
    alt: "img4",
    description: "Hamptons House",
    className: "grid-four-low",
  },
];

export default function ProjectsCards() {
  return (
    <div className="home-container">
      <div className="container">
        <div className="grid-container">
          {categories.map((category, index) => (
            <div key={index} className={`featured ${category.className}`}>
              <a href={category.to}>
                <div id={`img${index + 1}`} className="lil-overlay"></div>
                <img src={category.imgSrc} alt={category.alt} />
                <p className="main-description">{category.description}</p>
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
