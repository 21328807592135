import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './ImageSlider.css';
import overlayImage from '../assets/overlayImage.png';  // Ensure the path is correct

const ImageSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1500,  // Reduce speed for smoother transition
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: true,
    lazyLoad: 'ondemand',  // Lazy load images
  };

  const slides = [
    { image: "/slider.jpg", overlayImage },  // Make sure the image paths are correct
    { image: "/slider-2.jpg", text: ["Impeccable Custom Fabrication"], customClass: ["sans-serif-font"] },
    { image: "/slider-3.jpg", text: ["Meaningful Customer Service"], customClass: ["sans-serif-font"] }
  ];

  return (
    <div className="slider-container">
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <div key={index} className="slick-slide">
            <img
              src={slide.image}
              alt={`Slide ${index + 1}`}
              className="slider-image"
              loading="lazy"  // Enable lazy loading for better performance
            />
            <div className="slider-text">
              {slide.overlayImage ? (
                <img src={slide.overlayImage} alt="Overlay" className="overlay-image" />
              ) : (
                slide.text?.map((line, lineIndex) => (
                  <div key={lineIndex} className={slide.customClass[lineIndex] || slide.customClass[0]}>
                    {line}
                  </div>
                ))
              )}
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ImageSlider;
