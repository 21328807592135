import React from 'react';
import SubNavbar from '../components/AboutSubNav';
import InstallationProcess from '../components/InstallationProcess';
import AccordionComponent from '../components/AccordionComponent';

const Installation = () => {
  return (
    <div>
      <SubNavbar />
      <InstallationProcess />
      <AccordionComponent />
    </div>
  );
};

export default Installation;
