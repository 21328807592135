import React, { useState } from 'react';
import './AccordionComponent.css';

const AccordionItem = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="accordion-item">
      <button className={`accordion-button ${isOpen ? 'active' : ''}`} onClick={toggleAccordion}>
        {title}
      </button>
      <div className="accordion-content" style={{ display: isOpen ? 'block' : 'none' }}>
        {content}
      </div>
    </div>
  );
};

const Accordion = () => {
  const accordionData = [
    {
      title: 'Initial Meeting',
      content: (
        <div>
          <p>We work with contractors, interior designers, builders, and dealers alongside homeowners. In our first meeting, homeowners and designers will be asked to consider questions such as:</p>
          <ul>
            <li>What design style are you seeking?</li>
            <li>Do you prefer an organic look or a geometric style?</li>
            <li>What is your color palette?</li>
            <li>Will the stone be installed in a high-traffic area?</li>
            <li>Are you planning to use natural stone outside or inside?</li>
            <li>Is the project area exposed to sunlight, water or moisture, heat, or dramatic shifts in temperature?</li>
            <li>How important is sustainability to you?</li>
            <li>Does your kitchen project involve new or existing cabinets, sinks, or appliances?</li>
            <li>Would you like a backsplash? From the same material as a countertop or tile?</li>
          </ul>
          <p>Together, we’ll determine which natural stone or quartz is the right one for your project.</p>
        </div>
      ),
    },
    {
      title: 'Estimate',
      content: (
        <div>
          <p>Next, we’ll need a few materials from the building contractor to create an accurate estimate of the project. We’ll ask for:</p>
          <ul>
            <li>Project plans or rough counter sketch indicating sink, appliances, walls, etc.</li>
            <li>Material selection</li>
            <li>Edge selection</li>
            <li>Finish selection (polished, honed, or brushed)</li>
          </ul>
          <p>Once we have this information, you will receive a written estimate with the project layout for your review. Please note this is only an estimate and is subject to change based on final measurements taken during template.</p>
        </div>
      ),
    },
    {
      title: 'Contract',
      content: (
        <div>
          <p>All estimates, layouts, and materials must be signed for and returned to Morningstar with a 50% deposit before templating can occur. Once a signed contract and deposit is made, you will be contacted to discuss the earliest possible templating date.</p>
        </div>
      ),
    },
    {
      title: 'Templating',
      content: (
        <div>
          <p>On the day of templating, the contractor must ensure that all cabinets are attached and in a permanent position, leveled and secured. Cabinets that sit on the countertops need to be removed before template/install and should be reinstalled at least 24 hours AFTER the new countertops have been installed. All sinks, fixtures, and large appliances must be onsite before templating.</p>
          <h3>Issues that may delay templating:</h3>
          <ul>
            <li>Cabinets not installed completely</li>
            <li>Sink(s), fixtures, and large appliances not onsite</li>
            <li>Change in material choices, overall design of space</li>
          </ul>
        </div>
      ),
    },
    {
      title: 'Fabrication and Scheduling Installation',
      content: (
        <div>
          <p>With templating completed, your project will be added to the fabrication schedule. We will contact you to confirm the earliest date possible for installation. Your sales designer will work closely with you to ensure everything runs smoothly.</p>
        </div>
      ),
    },
    {
      title: 'Installation Day',
      content: (
        <div>
          <p>Natural and engineered stone is heavy, so please provide a clear path to the installation area. All plumbing, electrical, and gas connections need to be disconnected by the installation date. We neither disconnect nor reconnect any of these items. Arrangements should be made in advance by the home contractor to have the proper tradesperson handle both.</p>
          <p>On occasion, we may have to cut, grind, or polish the material onsite, which creates dust. We work very hard to minimize this. Morningstar is renowned for our accurate, precise installations, and we accept nothing less—even if that means some dust onsite. Please be aware of this potential.</p>
        </div>
      ),
    },
  ];

  return (
    <div className="accordion">
      {accordionData.map((item, index) => (
        <AccordionItem key={index} title={item.title} content={item.content} />
      ))}
    </div>
  );
};

export default Accordion;
