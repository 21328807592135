import React from 'react';
import './Tile.css';

const Tile = () => {
  return (
    <div className="tile-container">
      <div className="tile-content">
        <h2>Our Extensive Inventory</h2>
        <p>
        At NC Stone and Tile, we pride ourselves on offering a comprehensive range of products sourced from our numerous partners. Our extensive inventory includes a diverse selection of natural and engineered stones, tiles, and other materials, ensuring that you find the perfect match for your project. Whether you're working on residential or commercial properties, our high-quality products and dedicated service are designed to meet all your needs.
        </p>
        <a href='/inventory'><button>Learn More</button></a>
      </div>
    </div>
  );
};

export default Tile;
