// src/components/ProjectDetails.js
import React, { useState } from "react";
import "./ProjectDetails.css";
import Lightbox from "./Lightbox";
import "./Lightbox.css";
import ProjectsSubNavbar from "./ProjectsSubNav";

const ProjectDetails = ({ project }) => {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const openLightbox = (image) => {
    setSelectedImage(image);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
    setSelectedImage(null);
  };

  return (
      <><ProjectsSubNavbar /><div className="project-details-container">
      <h1 className="project-title">{project.title}</h1>
      <p className="project-overview">{project.overview}</p>

      {project.video && (
        <div className="project-video">
          <video width="100%" height="300" controls>
            <source src={project.video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      )}

      <div className="project-gallery">
        {project.images.map((image, index) => (
          <div
            key={index}
            className="gallery-image"
            onClick={() => openLightbox(image)}
          >
            <img src={image.src} alt={image.alt} />
          </div>
        ))}
      </div>

      <div className="project-description">
        <h2>What We Did</h2>
        <p>{project.description}</p>
      </div>

      {project.testimonial && (
        <div className="project-testimonial">
          <h2>Client Testimonial</h2>
          <blockquote>{project.testimonial}</blockquote>
        </div>
      )}

      <div className="project-info">
        <p><strong>Project Duration:</strong> {project.date}</p>
        <p><strong>Location:</strong> {project.location}</p>
      </div>

      {selectedImage && (
        <Lightbox
          isOpen={lightboxOpen}
          onRequestClose={closeLightbox}
          image={selectedImage} />
      )}
    </div></>
  );
};

export default ProjectDetails;
