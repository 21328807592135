// src/components/Lightbox.js
import React from "react";
import Modal from "react-modal";

const Lightbox = ({ isOpen, onRequestClose, image }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Image Lightbox"
      className="lightbox-modal"
      overlayClassName="lightbox-overlay"
    >
      <img src={image.src} alt={image.alt} className="lightbox-image" />
    </Modal>
  );
};

export default Lightbox;
