import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import L1 from '../assets/partners/Caesarstone.png'
import L2 from '../assets/partners/Dekton.png'
import L3 from '../assets/partners/MIofA.png'
import L4 from '../assets/partners/MSI.svg'
import L5 from '../assets/partners/cambria.svg'
import L6 from '../assets/partners/compac.png'
import L7 from '../assets/partners/corian.png'
import L8 from '../assets/partners/cosentino.png'
import L9 from '../assets/partners/nystone.png'
import L10 from '../assets/partners/silestone.svg'
import './PartnersCarousel.css';

const PartnersCarousel = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const partnersLogos = [
    L1,L2,L3,L4,L5,L6,L7,L8,L9,L10
  ];

  return (
    <div className="partners-carousel-container">
      <h2>Our Partners</h2>
      <Slider {...settings} className="partners-carousel">
        {partnersLogos.map((logo, index) => (
          <div key={index}>
            <img src={logo} alt={`Partner ${index + 1}`} className="partner-logo" />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default PartnersCarousel;
