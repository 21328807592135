import React from 'react';
import './InstallationProcess.css';

const InstallationProcess = () => {
  return (
    <div className="installation-process-container">
      <div className="installation-process-content">
        <h2>New Century Stone & Tile Installation & Process</h2>
        <p>
          New Century has set the bar for precise measuring, fabrication, and installation of stone and tile in 
          the US and Europe by solving complex structural challenges, taking the extra time to run scribes and 
          customize, paying attention to details, and ensuring the best possible finished product. Our streamlined, 
          clear process based on years of experience makes our work with building contractors, interior designers, 
          and homebuilders worry-free.
        </p>
        <p>
          From stone selection to the finishing touches on an installation, New Century handles it all.
        </p>
        <button>View Inventory</button>
      </div>
    </div>
  );
};

export default InstallationProcess;
