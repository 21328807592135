import React from 'react';
import { useLocation } from 'react-router-dom';
import './Receipt.css';
import html2pdf from 'html2pdf.js';

const Receipt = () => {
  const location = useLocation();
  const { estimates, userInfo } = location.state || {};

  if (!estimates || !userInfo) {
    return <p>Error: Missing data. Please go back and complete the form again.</p>;
  }

  const calculateTotalArea = (area) => {
    return area.width * area.height;
  };

  const handleSendEmail = () => {
    const data = { estimates, userInfo }; 

    fetch('https://receipt-sender-ee5bab7af367.herokuapp.com/api/send-receipt', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to send receipt');
        }
        return response.json();
      })
      .then(() => {
        alert('Receipt sent successfully!');
      })
      .catch(error => {
        alert('Error sending receipt: ' + error.message);
      });
  };

  const handleDownloadPDF = () => {
    const element = document.getElementById('receipt-content');
    html2pdf().from(element).save('receipt.pdf');
  };

  return (
    <div id="receipt-content" className="receipt-container">
      <h1>Receipt</h1>
        <h2>User Information:</h2>
        <p><strong>Name:</strong> {userInfo.name}</p>
        <p><strong>Email:</strong> {userInfo.email}</p>
        <p><strong>Phone:</strong> {userInfo.phone}</p>
        <p><strong>Address:</strong> {userInfo.address}</p>

        <h2>Cost Breakdown:</h2>
        {estimates.map((estimate, index) => (
          <div key={index} className="estimate-section">
            <h3>{estimate.name} Stone for {estimate.type}:</h3>
            <div className="area-breakdown">
              {estimate.estimate.areas.map((area, areaIndex) => (
                <div key={areaIndex}>
                  <h4>Area {areaIndex + 1}:</h4>
                  <p><strong>Total Area:</strong> {calculateTotalArea(area)} sq/ft</p>
                </div>
              ))}
              <p><strong>Stone Cost:</strong> ${estimate.estimate.stoneCost.toFixed(2)}</p>
              <p><strong>Outlet Cost:</strong> ${estimate.estimate.outletCost.toFixed(2)}</p>
              <p><strong>Cutout Cost:</strong> ${estimate.estimate.cutoutCost.toFixed(2)}</p>
              <p><strong>Fabrication Cost:</strong> ${estimate.estimate.fabricationCost.toFixed(2)}</p>
              <p><strong>Installation Cost:</strong> ${estimate.estimate.installationCost.toFixed(2)}</p>
              <h4><strong>Total Estimated Cost:</strong> ${estimate.estimate.totalCost.toFixed(2)}</h4>
            </div>
          </div>
        ))}

      <button onClick={handleSendEmail}>Send Receipt to Email</button>
      <button onClick={handleDownloadPDF}>Download as PDF</button>
    </div>
  );
};

export default Receipt;
