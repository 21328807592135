import React, { useState } from 'react';
import './RenovationCalculator.css';

const MeasurementInputs = ({ setWidth, setHeight }) => {
  return (
    <div className="measurement-inputs">
      <h3>Dimensions</h3>
      <label>
        Width (in feet):
        <input
          type="number"
          onChange={(e) => setWidth(e.target.value)}
          placeholder="Enter width"
        />
      </label>
      <label>
        Height (in feet):
        <input
          type="number"
          onChange={(e) => setHeight(e.target.value)}
          placeholder="Enter height"
        />
      </label>
    </div>
  );
};

export default MeasurementInputs;
