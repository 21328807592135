import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const CalculatorContainer = styled.div`
  background-color: #f4f1ed; /* Light beige background */
  color: #333;
  padding: 3rem 2rem;
  text-align: center;
  margin: 2rem auto;
  max-width: 800px;
  border-radius: 8px;
`;

const Title = styled.h2`
  font-size: 2.2rem;
  font-weight: 700;
  color: #2c3e50; /* Dark gray for title */
  margin-bottom: 1.5rem;
`;

const Description = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  color: #4d4d4d; /* Medium gray for readability */
  margin-bottom: 2rem;
`;

const Instructions = styled.ol`
  text-align: left;
  margin: 1rem auto;
  padding: 1rem 1.5rem;
  background-color: #ffffff; /* White background for instructions */
  border-radius: 8px;
  max-width: 600px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05); /* Subtle shadow */
`;

const InstructionItem = styled.li`
  margin-bottom: 1rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #444;
`;

const Button = styled.button`
  background-color: #003b29; /* Deep green matching other buttons */
  color: white;
  padding: 0.85rem 1.75rem;
  border: none;
  border-radius: 6px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  margin-top: 2rem;
  transition: background-color 0.3s, transform 0.1s ease-in-out;

  &:hover {
    background-color: #002c20; /* Slightly darker green on hover */
  }

  &:active {
    transform: scale(0.98); /* Subtle press effect */
  }
`;

function CalculatorInfo() {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/inventory'); // Starting point of the calculator process
  };

  return (
    <CalculatorContainer>
      <Title>Estimate Your Renovation Cost</Title>
      <Description>
        Use our renovation cost calculator to get an approximate estimate for your project. Follow the steps below for a smooth experience.
      </Description>
      <Instructions>
        <InstructionItem>1. Start by selecting your preferred slabs or stones from our inventory.</InstructionItem>
        <InstructionItem>2. Once you've chosen, proceed to select the type of renovation for each slab (e.g., kitchen, bathroom).</InstructionItem>
        <InstructionItem>3. Enter the dimensions of the space in the calculator to get an accurate estimate.</InstructionItem>
        <InstructionItem>4. Fill in your personal or business information in the form.</InstructionItem>
        <InstructionItem>5. Finally, view your estimated cost on the receipt page, with options to save it as a PDF or receive it via email.</InstructionItem>
      </Instructions>
      <Button onClick={handleButtonClick}>Start Using the Calculator</Button>
    </CalculatorContainer>
  );
}

export default CalculatorInfo;
