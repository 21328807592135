import React from 'react';
import './RenovationCalculator.css';


const CutoutInputs = ({ setCutouts, setOutlets }) => {
  return (
    <div className="cutout-inputs">
      <h3>Cutouts and Outlets</h3>
      <label>
        Number of Cutouts:
        <input
          type="number"
          onChange={(e) => setCutouts(e.target.value)}
          placeholder="Enter number of cutouts"
        />
      </label>
      <label>
        Number of Outlets and Holes:
        <input
          type="number"
          onChange={(e) => setOutlets(e.target.value)}
          placeholder="Enter number of outlets/holes"
        />
      </label>
    </div>
  );
};

export default CutoutInputs;
