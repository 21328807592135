import one from "../assets/Bob Stone Source CT/1.jpg"
import two from "../assets/Bob Stone Source CT/2.jpg"
import three from "../assets/Bob Stone Source CT/3.jpg"
import four from "../assets/Bob Stone Source CT/4.jpg"
import five from "../assets/Bob Stone Source CT/5.jpg"
import six from "../assets/Bob Stone Source CT/6.jpg"
import seven from "../assets/Bob Stone Source CT/7.jpg"
import eight from "../assets/Bob Stone Source CT/8.jpg"
import nine from "../assets/Bob Stone Source CT/9.jpg"
import twelve from "../assets/Bob Stone Source CT/12.jpg"
import thirteen from "../assets/Bob Stone Source CT/13.jpg"

// src/data/sampleProject.js
const sampleProject = {
    title: "Luxury Stone Fabrication and Installation for Residential Renovation",
    overview: "This project involved the complete fabrication and installation of high-end stone features in a residential renovation, blending modern design with timeless craftsmanship to enhance the home's aesthetic and functionality.",
    video: "https://res.cloudinary.com/dxlbndkts/video/upload/v1722540473/Video_e6fqjl.mp4", // YouTube embed link
    images: [
      { src: one, alt: "Result 1" },
      { src: two, alt: "Result 2" },
      { src: three, alt: "Result 3" },
      { src: four, alt: "Result 4" },
      { src: five, alt: "Result 5" },
      { src: six, alt: "Result 6" },
      { src: seven, alt: "Result 7" },
      { src: eight, alt: "Result 8" },
      { src: nine, alt: "Result 9" },
      { src: twelve, alt: "Result 12" },
      { src: thirteen, alt: "Result 13" },
    ],
    description: "In this modern home renovation, we expertly fabricated and installed a variety of custom stone features that blend style with functionality. Our work included the installation of sleek stone countertops and a custom kitchen island, creating a sophisticated and durable centerpiece for the kitchen. Additionally, we completed a stunning bathroom transformation with meticulously installed marble tiles and stone vanities. The outdoor space was enhanced with precision-cut stone steps and countertops, adding a refined touch to the home's exterior. Each element was crafted and installed with the highest level of craftsmanship, ensuring lasting beauty and quality.",
    testimonial: "We couldn't be happier with the results of our home renovation! The team at NC Stone and Tiles exceeded our expectations in every way. From the stunning stone countertops and custom kitchen island to the beautifully tiled bathroom, every detail was executed with precision and care. The craftsmanship is truly top-notch, and the transformation of our space is incredible. They were professional, attentive, and truly invested in making sure everything was perfect. We highly recommend NC Stone and Tiles to anyone looking for quality work and exceptional service.",
    date: "March 28 - July 8",
    location: "New Fairfield, CT"
  };
  
  export default sampleProject;
  