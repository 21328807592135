import React, { useState } from 'react';
import './ContactForm.css';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    details: '',
    file: null,
  });

  const [isSubmitting, setIsSubmitting] = useState(false); // Submission state
  const [cooldown, setCooldown] = useState(false); // Cooldown state

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, file: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isSubmitting || cooldown) return; // Prevent multiple clicks or during cooldown

    setIsSubmitting(true); // Set submission state
    const formDataToSend = new FormData();
    formDataToSend.append('name', formData.name);
    formDataToSend.append('email', formData.email);
    formDataToSend.append('phone', formData.phone);
    formDataToSend.append('address', formData.address);
    formDataToSend.append('details', formData.details);
    if (formData.file) {
      formDataToSend.append('file', formData.file); // Attach the file if it exists
    }

    try {
      const response = await fetch('https://contact-submission-2d0ea4d66bf2.herokuapp.com/send', {
        method: 'POST',
        body: formDataToSend,
      });

      // Always show success message even if the request fails
      alert('Oh, it\'s been sent! We will get back to you shortly.');

      setCooldown(true); // Start cooldown
      setTimeout(() => {
        setCooldown(false); // Remove cooldown after 20 seconds
      }, 20000); // 20 seconds cooldown

      setIsSubmitting(false); // Allow new submission after cooldown
    } catch (error) {
      console.error('Error:', error);
      // Show the same success message even on failure
      alert('Oh, it\'s been sent! We will get back to you shortly.');
      setIsSubmitting(false); // Reset submission state
    }
  };

  return (
    <div className="contact-form-container">
      <h2>Interested in working with us? Tell us about your project.</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input type="text" id="name" name="name" required value={formData.name} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input type="email" id="email" name="email" required value={formData.email} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label htmlFor="phone">Phone</label>
          <input type="tel" id="phone" name="phone" required value={formData.phone} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label htmlFor="address">Address</label>
          <input type="text" id="address" name="address" required value={formData.address} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label htmlFor="details">Please give us the details about your project.</label>
          <textarea id="details" name="details" required value={formData.details} onChange={handleChange}></textarea>
        </div>
        <div className="form-group">
          <label htmlFor="file">Please upload your plans if available</label>
          <input type="file" id="file" name="file" onChange={handleFileChange} />
        </div>
        <button type="submit" disabled={isSubmitting || cooldown}>
          {isSubmitting || cooldown ? 'Submitting...' : 'Submit'} {/* Button text changes based on state */}
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
