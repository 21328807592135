// Footer.js
import React from 'react';
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';
import './Footer.css';
import Logo from '../assets/logo.png'

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer-logo">
        <img src={Logo} alt="Company Logo" />
      </div>
      <div className="footer-contact-info">
        <div>
          <i className="fa fa-map-marker"></i>
          165 Broadway 23 floor, Manhattan, NY 10006
        </div>
        <div>
          <i className="fa fa-clock"></i>
          Showroom Appointments Available:<br/>
          Monday – Friday 9am to 5pm & Saturdays 9am - 2pm 
        </div>
        <div>
          <i className="fa fa-phone"></i>
            +1 (917) 640-7693
        </div>
        <div>
          <i className="fa fa-envelope"></i>
          info@ncstoneandtiles.com
        </div>
      </div>
      <div className="footer-social-icons">
        <a href="https://www.facebook.com/newcenturystone.tile" target="_blank" rel="noopener noreferrer">
          <FaFacebook />
        </a>
        <a href="https://www.instagram.com/ncstoneandtiles/" target="_blank" rel="noopener noreferrer">
          <FaInstagram />
        </a>
        <a href="https://www.linkedin.com/in/abdusame-mirzoev-80810548" target="_blank" rel="noopener noreferrer">
          <FaLinkedin />
        </a>
      </div>
    </div>
  );
};

export default Footer;
