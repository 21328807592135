import one from "../assets/Artem Stone Work Office/1-Feb 11, 2024 15-35-56-VaCy.jpg"
import two from "../assets/Artem Stone Work Office/2-Feb 11, 2024 15-40-44-jGbE.jpg"
import three from "../assets/Artem Stone Work Office/3-Feb 11, 2024 15-40-50-SbMQ.jpg"
import four from "../assets/Artem Stone Work Office/4-Feb 11, 2024 15-54-51-CbYp.jpg"
import five from "../assets/Artem Stone Work Office/5-Feb 11, 2024 16-03-36-7AEc.jpg"
import six from "../assets/Artem Stone Work Office/7-Feb 15, 2024 20-48-11-oLKV.jpg"
import seven from "../assets/Artem Stone Work Office/8-Feb 15, 2024 20-48-20-eJ2F.jpg"
import eight from "../assets/Artem Stone Work Office/9-Feb 15, 2024 20-48-25-maUK.jpg"
import nine from "../assets/Artem Stone Work Office/10-Feb 15, 2024 20-48-27-rPpF.jpg"
import ten from '../assets/Artem Stone Work Office/11-Feb 15, 2024 20-48-40-XmZJ.jpg'
import eleven from '../assets/Artem Stone Work Office/12-Feb 15, 2024 20-48-46-TcA7.jpg'
import twelve from '../assets/Artem Stone Work Office/13-Feb 15, 2024 20-48-59-tyUj.jpg'
import thirteen from '../assets/Artem Stone Work Office/14-Feb 15, 2024 20-49-54-TqFV.jpg'
import fourteen from '../assets/Artem Stone Work Office/15-Feb 15, 2024 20-49-55-VrNu.jpg'
import fifteen from '../assets/Artem Stone Work Office/16-Feb 15, 2024 20-52-08-nYjs.jpg'
import sixteen from '../assets/Artem Stone Work Office/17-Feb 15, 2024 20-54-02-3hF4.jpg'
import seventeen from '../assets/Artem Stone Work Office/18-Feb 15, 2024 20-54-07-mgXF.jpg'
import eightteen from '../assets/Artem Stone Work Office/19-Feb 15, 2024 20-54-10-ZdUV.jpg'
import nineteen from '../assets/Artem Stone Work Office/20-Feb 15, 2024 20-54-16-qhEy.jpg'
import twenty from '../assets/Artem Stone Work Office/21-Apr 12, 2024 20-18-42-DYxM.jpg'
import twentyOne from '../assets/Artem Stone Work Office/22-Apr 12, 2024 20-18-51-uufT.jpg'
import twentyTwo from '../assets/Artem Stone Work Office/23-Apr 12, 2024 20-18-59-zXzS.jpg'
import twentyThree from '../assets/Artem Stone Work Office/24-Apr 12, 2024 20-19-02-X8gt.jpg'
import twentyFour from '../assets/Artem Stone Work Office/25-Apr 12, 2024 20-19-48-8w7g.jpg'


// src/data/sampleProject.js
const sampleProject = {
    title: "Elegant Stonework for Modern Office Space",
    overview: "This project features bespoke stone installations tailored to elevate the aesthetic and functionality of a modern office environment, showcasing the fusion of form and function.",
    video: "https://res.cloudinary.com/dxlbndkts/video/upload/v1726066496/NY_Office_fpgnwa.mp4", // Project embed link
    images: [
      { src: one, alt: "Result 1" },
      { src: two, alt: "Result 2" },
      { src: three, alt: "Result 3" },
      { src: four, alt: "Result 4" },
      { src: five, alt: "Result 5" },
      { src: six, alt: "Result 6" },
      { src: seven, alt: "Result 7" },
      { src: eight, alt: "Result 8" },
      { src: ten, alt: "Result 10" },
      { src: eleven, alt: "Result 11" },
      { src: twelve, alt: "Result 12" },
      { src: thirteen, alt: "Result 13" },  
      { src: fifteen, alt: "Result 15" },
      { src: sixteen, alt: "Result 16" },
      { src: seventeen, alt: "Result 17" },
      { src: eightteen, alt: "Result 18" },
      { src: nineteen, alt: "Result 19" }, 
      { src: twentyOne, alt: "Result 21" },
      { src: twentyTwo, alt: "Result 22" },
      { src: twentyFour, alt: "Result 24"},
    ],
    description: "In this contemporary office renovation, our team was tasked with designing and installing custom stone elements that bring a sense of luxury and durability to the workspace. The project includes the installation of striking marble countertops and elegant stone tables, perfectly complementing the modern interior design. Each piece was crafted with precision to ensure seamless integration with the office's overall aesthetic. From the polished reception desks to the sleek conference room tables, every element was designed to make a statement while maintaining practicality for daily use. The result is a sophisticated office environment that reflects both style and professionalism.",
    testimonial: "Working with NC Stone and Tiles was an absolute pleasure. Their attention to detail and dedication to delivering high-quality craftsmanship is evident in every piece they installed. The custom stonework not only enhances the beauty of our office but also adds a level of sophistication that has impressed both our team and our clients. The project was completed on time and within budget, and the entire process was smooth and professional. We highly recommend NC Stone and Tiles for any commercial renovation project.",
    date: "Feb 11, 2024 - Apr 12, 2024",
    location: "New York"
  };
  
  export default sampleProject;
  