import React from 'react';
import './ProjectsSubNav.css';

const ProjectsSubNavbar = () => {
  return (
    <div className="projects-sub-navbar">
      <ul className="projects-sub-navbar-menu">
        <li><a href="/projects/fairfield">Fairfield</a></li>
        <li><a href="/projects/H&M">H&M</a></li>
        <li><a href="/projects/office">Office</a></li>
        <li><a href='/projects/hamptons'>Hamptons</a></li>
      </ul>
    </div>
  );
};

export default ProjectsSubNavbar;
