import React from 'react';
import ProjectsCards from '../components/ProjectsCards';
import ProjectsSubNavbar from '../components/ProjectsSubNav';


const Projects = () => {
  return (
    <div>
        <ProjectsSubNavbar />
        <ProjectsCards />
    </div>
  );
};

export default Projects;
