// TotalCost.js
import React, { useEffect } from "react";

// Modified stone, fabrication, and installation cost calculation functions
const getStoneCost = (area, stonePrice) => {
  const baseMultiplier = 65;
  const increments = Math.ceil(area / 40);
  const effectiveArea = baseMultiplier * increments;
  return stonePrice * effectiveArea;
};

const getFabricationCost = (area, fabricationCostPerSqFt) => {
  const baseMultiplier = 65;
  const increments = Math.ceil(area / 40);
  const effectiveArea = baseMultiplier * increments;
  return fabricationCostPerSqFt * effectiveArea;
};

const getInstallationCost = (area) => {
  const effectiveArea = area < 45 ? 45 : area;
  return effectiveArea * 45;
};

const TotalCost = ({
  areas = [],
  stonePrice,
  outletCount,
  cutoutCount,
  fabricationCostPerSqFt,
  onEstimateCalculated
}) => {
  const totalArea = areas.reduce((acc, area) => {
    const areaWidth = parseFloat(area.width) || 0;
    const areaHeight = parseFloat(area.height) || 0;
    return acc + (areaWidth * areaHeight);
  }, 0);

  const stoneCost = getStoneCost(totalArea, stonePrice);
  const fabricationCost = getFabricationCost(totalArea, fabricationCostPerSqFt);
  const installationCost = getInstallationCost(totalArea);

  const outletCost = outletCount * 25;
  const cutoutCost = cutoutCount * 200;
  const totalCost = stoneCost + outletCost + cutoutCost + fabricationCost + installationCost;

  useEffect(() => {
    const estimate = {
      totalArea,
      stoneCost,
      outletCost,
      cutoutCost,
      fabricationCost,
      installationCost,
      totalCost
    };
    onEstimateCalculated(estimate);
  }, [totalArea, stoneCost, outletCost, cutoutCost, fabricationCost, installationCost, totalCost]);

  return (
    <div className="total-cost-container">
      <h2>Estimated Cost Breakdown:</h2>
      <p>Stone Cost: ${stoneCost.toFixed(2)}</p>
      <p>Outlet Cost: ${outletCost.toFixed(2)}</p>
      <p>Cutout Cost: ${cutoutCost.toFixed(2)}</p>
      <p>Fabrication Cost: ${fabricationCost.toFixed(2)}</p>
      <p>Installation Cost: ${installationCost.toFixed(2)}</p>
      <h3>Total Estimated Cost: ${totalCost.toFixed(2)}</h3>
    </div>
  );
};

export default TotalCost;
