import React from 'react';
import './CareersAndInstallation.css';

const CareersAndInstallation = () => {
  return (
    <div className="careers-installation-container">
      <div className="section">
        <div className="content" id='careers'>
            <div className='description'>
                <h2>Careers</h2>
                <p>
                    Deeply committed to our craft and built around work/life balance, this is more than a job, it’s a way of life.
                </p>
                <a href='/careers'><button>LEARN MORE</button></a>
            </div>
        </div>
      </div>
      <div className="section">
        <div className="content" id='installation'>
            <div className='description'>
                <h2>Installation & Process</h2>
                    <p>
                        Meticulous craft and care go into the fabrication and installation of Morningstar products.
                    </p>
                <a href='/installation'><button>LEARN MORE</button></a>
            </div>
        </div>
      </div>
    </div>
  );
};

export default CareersAndInstallation;
