import React from 'react';
import './WorkWithUs.css';
import workWithUsImage from '../assets/CareersDesc.jpg';

const WorkWithUs = () => {
  return (
    <div className="work-with-us-container">
      <div className="work-with-us-content">
        <h2>Work with us</h2>
        <p>Employee-owned, deeply committed to our craft, and built around work/life balance, this is more than a job, it’s a way of life.</p>
        <p>You should be part of it.</p>
        <p>If you value craftsmanship and working with your hands, are committed to producing the best quality workmanship possible, want to learn new skills and advance into a career with growth, and become part of a close-knit, ownership team who works hard and has fun while doing it, New Century is where you want to be.</p>
      </div>
      <div className="work-with-us-section">
        <div className="work-with-us-image">
          <img src={workWithUsImage} alt="Work with us" />
        </div>
        <div className="work-with-us-text">
          <h3>OUR BELIEFS, YOUR BENEFITS</h3>
          <p>We believe that a strong team makes a great business. There’s an energy across New Century that’s contagious – where caring and committed people come together to create great work every day. That deserves to be supported, recognized and rewarded.</p>
          <p><strong>Raise the Bar:</strong> We have a well-earned reputation throughout Maine for unmatched precision in natural stone and tile fabrication, installation, and excellent customer service. As part of our team, you’ll become an expert in these fields.</p>
          <p><strong>Work/Life Balance:</strong> We believe in the importance of life outside work. That’s why we keep a Monday-Friday work week with weeknights and weekends off, and we provide earned paid leave and earned time off.</p>
          <p><strong>Take Ownership:</strong> We all have the passion and drive to make the company succeed because when it does, so do we. All employees are enrolled in our Employee Stock Ownership Program (ESOP) after 18 months of employment.</p>
          <p><strong>Support the Team:</strong> We operate as a work family. Support means providing a benefits package that includes health, dental and vision coverage, short-term disability and life insurance, 401K retirement and ESOP enrollment.</p>
        </div>
      </div>
    </div>
  );
};

export default WorkWithUs;
