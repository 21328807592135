import React from 'react';
import './StoneSurfaces.css';

const StoneSurfaces = () => {
  return (
    <div className="stone-surfaces-container">
      <div className="stone-surfaces-content">
        <h2>Our Services</h2>
        <p>
        At NC Stone and Tile, we specialize in the design, fabrication, and installation of custom countertops, offering the widest selection of both natural and engineered stones. Our services cater to a variety of projects, including newly constructed residences, condominium cosmetic rehabs, hotel renovations, and other residential or commercial properties. Our experienced team of professionals is committed to delivering unparalleled customer service, exceptional craftsmanship, and top-quality products at a remarkable value.
        </p>
        <a href='/services'><button>LEARN MORE</button></a>
      </div>
    </div>
  );
};

export default StoneSurfaces;
