import one from "../assets/Derek Fireplace Hamptons/1-Dec 06, 2023 18-16-25-Z3FG.jpg"
import two from "../assets/Derek Fireplace Hamptons/2-Feb 26, 2024 19-14-06-HxAq.jpg"
import three from "../assets/Derek Fireplace Hamptons/3-Feb 26, 2024 19-25-18-Z2PK.jpg"
import four from "../assets/Derek Fireplace Hamptons/4-Mar 29, 2024 18-34-13-y9if.jpg"
import five from "../assets/Derek Fireplace Hamptons/6-Mar 29, 2024 18-32-18-4cJc.jpg"
import six from "../assets/Derek Fireplace Hamptons/7-Mar 29, 2024 18-32-07-m6h5.jpg"
import seven from "../assets/Derek Fireplace Hamptons/8-Mar 29, 2024 18-31-59-TKHY.jpg"
import nine from "../assets/Derek Fireplace Hamptons/10-Mar 29, 2024 18-24-41-pfge.jpg"
import ten from '../assets/Derek Fireplace Hamptons/11-Mar 29, 2024 18-24-36-1YsJ.jpg'
import eleven from '../assets/Derek Fireplace Hamptons/12-Mar 29, 2024 18-23-32-CTAz.jpg'
import twelve from '../assets/Derek Fireplace Hamptons/13-May 08, 2024 22-06-08-MLcs.jpg'
import thirteen from '../assets/Derek Fireplace Hamptons/14-May 08, 2024 22-06-30-ppDj.jpg'
import fourteen from '../assets/Derek Fireplace Hamptons/15-May 08, 2024 22-06-40-pxas.jpg'
import fifteen from '../assets/Derek Fireplace Hamptons/16-May 08, 2024 22-10-06-S93z.jpg'
import sixteen from '../assets/Derek Fireplace Hamptons/17-May 08, 2024 22-16-04-Sjvb.jpg'
import seventeen from '../assets/Derek Fireplace Hamptons/18-May 08, 2024 22-17-39-E7mq.jpg'
import eightteen from '../assets/Derek Fireplace Hamptons/19-May 08, 2024 22-18-25-bHRJ.jpg'
import nineteen from '../assets/Derek Fireplace Hamptons/20-May 08, 2024 22-18-30-xSQV.jpg'
import twenty from '../assets/Derek Fireplace Hamptons/21-May 08, 2024 22-20-31-vDeD.jpg'
import twentyOne from '../assets/Derek Fireplace Hamptons/22-May 08, 2024 22-20-51-EAJa.jpg'
import twentyTwo from '../assets/Derek Fireplace Hamptons/23-May 08, 2024 22-20-53-jSnm.jpg'

// src/data/sampleProject.js
const sampleProject = {
    title: "Modern Luxury Home Renovation in Hamptons, NY",
    overview: "A comprehensive luxury home renovation in the Hamptons, NY, featuring custom stone fabrications and installations that blend indoor elegance with outdoor functionality, showcasing top-tier craftsmanship and sophisticated design.",
    video: "https://res.cloudinary.com/dxlbndkts/video/upload/v1722710264/Hamptons_ekl6rc.mp4", // Project embed link
    images: [
      { src: one, alt: "Result 1" },
      { src: two, alt: "Result 2" },
      { src: three, alt: "Result 3" },
      { src: four, alt: "Result 4" },
      { src: five, alt: "Result 5" },
      { src: six, alt: "Result 6" },
      { src: seven, alt: "Result 7" },
      { src: nine, alt: "Result 9" },
      { src: ten, alt: "Result 10" },
      { src: eleven, alt: "Result 11" },
      { src: twelve, alt: "Result 12" },
      { src: thirteen, alt: "Result 13" },
      { src: fourteen, alt: "Result 14" }, 
      { src: fifteen, alt: "Result 15" },
      { src: sixteen, alt: "Result 16" },
      { src: seventeen, alt: "Result 17" },
      { src: eightteen, alt: "Result 18" },
      { src: nineteen, alt: "Result 19" },
      { src: twenty, alt: "Result 20" }, 
      { src: twentyOne, alt: "Result 21" },
      { src: twentyTwo, alt: "Result 22" },
    ],
    description: "In this comprehensive luxury home renovation, we provided bespoke stone fabrications and meticulous installations that transformed the space into a modern masterpiece. Our work included the installation of elegant marble countertops and custom kitchen islands, which serve as both functional and aesthetic centerpieces in the home. We also crafted sophisticated bathroom vanities and installed floor-to-ceiling tiles in multiple bathrooms, adding to the home’s refined ambiance. The project extended to the outdoor living area, where we installed sleek stone countertops and surfaces, seamlessly blending indoor elegance with outdoor functionality. Every element of this project was executed with the utmost precision and attention to detail, resulting in a home that exudes luxury and timeless style.",
    testimonial: "We are absolutely thrilled with the transformation of our home! The team at NC Stone and Tiles did an exceptional job, bringing our vision to life with stunning stonework and flawless installations. The custom kitchen island is the perfect focal point, and the bathrooms are now luxurious retreats. Their attention to detail and commitment to quality truly set them apart. From start to finish, the entire process was smooth and professional. We couldn’t be happier with the results and highly recommend NC Stone and Tiles for anyone looking to elevate their home’s interior and exterior spaces.",
    date: "November 9, 2023 - Jun 13, 2024",
    location: "Hamptons, NY"
  };
  
  export default sampleProject;
  